export const URL = {
  // dev

  // API_BASE_URL: "http://localhost:6001",
  API_BASE_URL: "https://api.devimart.com",
  // API_BASE_URL: "http://192.168.1.9:6001",

  // apipublic

  login_admin: "/publicApi/signinAdmin",

  // income
  getlavelincomeAdmin: "/adminApi/getlavelincomeAdmin",
  getDireactncomeAdmin: "/adminApi/getDireactncomeAdmin",
  getBonusIncomeAdmin: "/adminApi/getBonusIncomeAdmin",
  getMatchingIncomeAdmin: "/adminApi/getMatchingIncomeAdmin",
  getReMatchingIncomeAdmin: "/adminApi/getReMatchingIncomeAdmin",
  getReDireactncomeAdmin: "/adminApi/getReDireactncomeAdmin",
  getRelavelincomeAdmin: "/adminApi/getRelavelincomeAdmin",

  // blog

  createBlogs: "/adminApi/createBlogs",
  getBlogList: "/adminApi/getBlogList",
  updateBlogs: "/adminApi/updateBlogs",
  deleteBlog: "/adminApi/deleteBlog",
  // blog

  createBranches: "/adminApi/createBranches",
  getBranchesList: "/adminApi/getBranchesList",
  updateBranches: "/adminApi/updateBranches",
  deleteBranch: "/adminApi/deleteBranch",

  // brand
  updateperentbrand: "/adminApi/updateperentbrand",
  createbrand: "/adminApi/createbrand",
  getBrandList: "/adminApi/getBrandList",
  updateInvoiceProfile: "/adminApi/updateInvoiceProfile",

  createAbout: "/adminApi/createAbout",
  getPagesm: "/adminApi/getpageList",
  updatePageAdmin: "/adminApi/updatePage",
  deletePage: "/adminApi/deletePage",

  // referral
  refferalProgramList: "/adminApi/refferalProgramList",
  ApplyrefferalstatusUpdate: "/adminApi/ApplyrefferalstatusUpdate",

  createFooterPageType: "/adminApi/createFooterPageType",
  footerPageTypesDelete: "/adminApi/footerPageTypesDelete",
  footerPageTypesUpdate: "/adminApi/footerPageTypesUpdate",
  createFooterPageList: "/adminApi/createFooterPageList",

  footerPageTypes: "/adminApi/footerPageTypes",
  getpageListfooter: "/adminApi/getpageListfooter",
  footerPagepageListet: "/adminApi/footerPagepageListet",
  footerPageListsDelete: "/adminApi/footerPageListsDelete",

  updateHomeLogo: "/adminApi/updateHomeLogo",
  updateInvoiceLogo: "/adminApi/updateInvoiceLogo",
  updateLoginLogo: "/adminApi/updateLoginLogo",
  updateRegisterLogo: "/adminApi/updateRegisterLogo",

  addJobCategory: "/adminApi/addJobCategory",
  create_category: "/adminApi/createCategory",
  update_category: "/adminApi/updateperentCate",
  updatejobCate: "/adminApi/updatejobCate",
  delete_category: "/adminApi/deleteCategory",
  deleteBrand: "/adminApi/deleteBrand",
  deleteJobCategory: "/adminApi/deleteJobCategory",
  getcateAllList: "/adminApi/getcateAllList",
  statusCategory: "/adminApi/statusCategory",
  statusBrand: "/adminApi/statusBrand",
  statusJobCategory: "/adminApi/statusJobCategory",

  subcreateCategory: "/adminApi/subcreateCategory",
  getsubCategoryList: "/adminApi/getsubCategoryList",
  getsubcateAllList: "/adminApi/getsubcateAllList",
  deletesubCategory: "/adminApi/deletesubCategory",

  updateperentsubCate: "/adminApi/updateperentsubCate",
  updateperentsubsubCate: "/adminApi/updateperentsubsubCate",

  subsubcreateCategory: "/adminApi/subsubcreateCategory",
  deletesubsubCategory: "/adminApi/deletesubsubCategory",
  getusercertifiteListAdmin: "/adminApi/getusercertifiteListAdmin",
  getusercertifitedeleteAdmin: "/adminApi/getusercertifitedeleteAdmin",
  getusercertifiteupdateAdmin: "/adminApi/getusercertifiteupdateAdmin",

  statusSubCategory: "/adminApi/statusSubCategory",
  statusSubSubCategory: "/adminApi/statusSubSubCategory",
  vendorPaybleAmountList: "/adminApi/vendorPaybleAmountList",
  vendorPaybleAmountDetails: "/adminApi/vendorPaybleAmountDetails",
  vendorPaybleAmountstatusUpdate: "/adminApi/vendorPaybleAmountstatusUpdate",
  createEducate: "/adminApi/createEducate",
  getDeducateList: "/adminApi/getDeducateList",
  deleteeduCateBtn: "/adminApi/deleteeduCateBtn",
  EditeduCateBtn: "/adminApi/EditeduCateBtn",
  educationAllCreate: "/adminApi/educationAllCreate",
  geteducatetttcate: "/adminApi/geteducatetttcate",
  geteduAllList: "/adminApi/geteduAllList",
  educationstatusUpdate: "/adminApi/educationstatusUpdate",
  educationstatusDelete: "/adminApi/educationstatusDelete",
  examcreate: "/adminApi/examcreate",
  examDeletebyadmin: "/adminApi/examDeletebyadmin",
  getListList: "/adminApi/getexamList",
  getMarqueesList: "/adminApi/getMarqueesList",
  createMarquees: "/adminApi/createMarquees",
  deleteMarquee: "/adminApi/deleteMarquee",
  updatemarquseAdmin: "/adminApi/updatemarquseAdmin",
  getlistingListAdmin: "/adminApi/getlistingListAdmin",
  updateDynemicBtn: "/adminApi/updateDynemicBtn",

  users: "/adminApi/getSellerList",
  user_inactive: "/adminApi/getUserListActive",
  getUserIdListActive: "/adminApi/getUserIdListActive",
  getUserListActivedistributor: "/adminApi/getUserListActivedistributor",
  lostfoundActive: "/adminApi/lostfoundActive",
  addFoundByAdmin: "/adminApi/addPoint",
  KycstatusUpdateByAdmin: "/adminApi/KycstatusUpdateByAdmin",
  getUserListseacrch: "/adminApi/searchUserList",
  searchUserListStatus: "/adminApi/searchUserListStatus",
  getSellerListStatus: "/adminApi/getSellerListStatus",
  getSellerListAdminrolevise: "/adminApi/getSellerListAdminrolevise",
  getBettalList: "/adminApi/getBettalList",
  deleteByAdminBettal: "/adminApi/deleteByAdminBettal",
  getBettalListAdmin: "/adminApi/getBettalListAdmin",
  getwithdrwarlAdminList: "/adminApi/getwithdrwarlAdminList",
  completeWithdrawalReq: "/adminApi/completeWithdrawalReq",
  getkycpending: "/adminApi/getkycpending",
  getTransactionList: "/adminApi/getTransactionList",
  getProfileDetails: "/adminApi/getProfileDetails",
  getbDetails: "/adminApi/getbDetails",
  dashboard: "/adminApi/deshBoradData",
  getsetting: "/adminApi/getsetting",
  createSetting: "/adminApi/createSetting",
  deleteUserByadmin: "/adminApi/deleteUserByadmin",
  edituserProfileByadmin: "/adminApi/edituserProfileByadmin",
  edituserKycByadmin: "/adminApi/edituserKycByadmin",
  getctGpg: "/adminApi/getctGpg",
  // addPoint: "/adminApi/addPoint",
  getsubsubCategoryList: "/adminApi/getsubsubCategoryList",

  verifykeyByAdmin: "/adminApi/verifykeyByAdmin",
  categoryWiseSale: "/adminApi/categoryWiseSale",

  user_contact: "/api/user_contact",
  all_category: "/api/all_category",
  all_category_main: "/adminApi/getCategoryList",
  getJobCategoryList: "/adminApi/getJobCategoryList",

  // product
  getProductList: "/adminApi/getProductList",
  searchProduct: "/adminApi/searchProduct",
  listingProductAdmin: "/adminApi/listingProductAdmin",
  statusProduct: "/adminApi/statusProduct",
  statuslistingadmin: "/adminApi/statuslistingadmin",
  deletelistingAdmin: "/adminApi/deletelistingAdmin",
  deleteProduct: "/adminApi/deleteProduct",
  productDetails: "/adminApi/productDetails",
  LostFounddeleteadmin: "/adminApi/LostFounddeleteadmin",

  createProduct2: "/adminApi/createProduct2",
  createProductvariations: "/adminApi/createProductvariations",
  updateProductvariations: "/adminApi/updateProductvariations",
  updateProduct: "/adminApi/updateProduct",
  // order
  getuserOrderAdmin: "/adminApi/getuserOrderAdmin",

  getInvoceDetails: "/adminApi/getInvoceDetails",
  getuserOrderDetails: "/adminApi/getuserOrderDetails",
  cancelOrderByAdmin: "/adminApi/cancelOrderByAdmin",
  getSearchOrderAdmin: "/adminApi/getSearchOrderAdmin",

  getuserreturnOrderAdmin: "/adminApi/getuserreturnOrderAdmin",
  getSearchReturnOrderAdmin: "/adminApi/getSearchReturnOrderAdmin",
  getsubsubcate: "/vendorApi/getsubsubcate",

  getmqChalenge: "/adminApi/getmqChalenge",
  getduqeChalenge: "/adminApi/getduqeChalenge",
  MqchalengeUprove: "/adminApi/MqchalengeUprove",
  DuqechalengeUprove: "/adminApi/DuqechalengeUprove",
  ctLeaderBoad: "/adminApi/ctLeaderBoad",

  teamCommissionByDate: "/adminApi/teamCommissionByDate",

  teamCommissionByDateBypagi: "/adminApi/teamCommissionByDateBypagi",
  getTicketList: "/adminApi/getTicketList",
  searchTicket: "/adminApi/searchTicket",
  getmessageSupportList: "/adminApi/getmessageSupportList",
  createMessage: "/adminApi/createMessage",
  addlicence: "/adminApi/addlicence",

  // slider
  createSlider: "/adminApi/createSlider",
  getsliderList: "/adminApi/getsliderList",
  updateSlider: "/adminApi/updateSlider",
  deleteSlider: "/adminApi/deleteSlider",

  // dynrmicbtn
  createDynemicBtn: "/adminApi/createDynemicBtn",
  getDynemicBtnList: "/adminApi/getDynemicBtnList",
  educationallUpdateadmin: "/adminApi/educationallUpdateadmin",
  geteducatetDetails: "/adminApi/geteducatetDetails",
  // updateSlider: "/adminApi/updateSlider",
  deleteDynemicBtn: "/adminApi/deleteDynemicBtn",

  lostfoundlistAdmin: "/adminApi/lostfoundlistAdmin",
  questioncreate: "/adminApi/questioncreate",

  createPackage: "/adminApi/createPackage",
  deletePackage: "/adminApi/deletePackage",
  // deletesubCategory: "/adminApi/deletesubCategory",
  // deletesubsubCategory: "/adminApi/deletesubsubCategory",
  updatePackage: "/adminApi/updatePackage",
  getPackageList: "/adminApi/getPackageList",
  searchPackage: "/adminApi/searchPackage",
  videossubmit: "/adminApi/videossubmit",
  videolistAdmin: "/adminApi/videolistAdmin",
  videodelete: "/adminApi/videodelete",
  questionList: "/adminApi/questionList",
  delete_question: "/adminApi/delete_question",
  update_question: "/adminApi/update_question",
  // product Add

  //

  quiz_list: "/api/quiz_list",
  create_quiz: "/api/create_quiz",
  delete_quiz: "/api/delete_quiz",
  update_quiz: "/api/update_quiz",
  questions_list: "/api/questions_list",

  create_quiz_question: "/api/create_quiz_question",

  user_allcontact: "/api/user_allcontact",

  quiz_active: "/api/quiz_active",

  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",

  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",

  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",

  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  getHomePro: "/publicApi/getHomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",
  // getPackageList: "/sellerApi/getPackageList",
  createPurchase: "/sellerApi/createPurchase",
  getsellerTransaction: "/sellerApi/getsellerTransaction",
  getprofile: "/sellerApi/getprofile",
  updatesellerProduct: "/sellerApi/updatesellerProduct",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",

  // getmessageSupportList: "/sellerApi/getmessageSupportList",

  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/sellerApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",
  getExpireDate: "/sellerApi/getExpireDate",
  // getsliderList: "/publicApi/getsliderList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  uploadStateCountryData: "/adminApi/set/locationData",
  getStateCountryData: "/publicApi/get/locationData",
};
